.input-inline {
    line-height: 0 !important;
    padding: 0;
}

.input-border {
    border-bottom: 0 !important;
}

.p-inputtext {
    padding: 0px;
}

.p-dropdown {
    width: 50px !important;
}

/*.p-dropdown .p-dropdown-label {
    padding-right: 0 !important;
}*/

.p-dropdown .p-dropdown-clear-icon {
    margin-top: -0.75rem !important;
}