.mapboxgl-map.mouse-drag .mapboxgl-canvas-container.mapboxgl-interactive {
    cursor: url('../assets/cursor/movefleche.cur'), auto !important ;
}

.mapboxgl-map.mouse-drag.mode-direct_select .mapboxgl-canvas-container.mapboxgl-interactive {
    cursor: url('../assets/cursor/movefleche.cur'), auto !important ;
}

.mapboxgl-map.mouse-move.mode-direct_select .mapboxgl-canvas-container.mapboxgl-interactive {
    cursor: url('../assets/cursor/movefleche.cur'), auto !important ;
}

.mapboxgl-map.mode-direct_select.feature-vertex.mouse-move .mapboxgl-canvas-container.mapboxgl-interactive {
    cursor: url('../assets/cursor/lizard.cur'), auto !important ;
}

.mapboxgl-map.feature-vertex.mode-direct_select.mouse-drag .mapboxgl-canvas-container.mapboxgl-interactive {
    cursor: url('../assets/cursor/lizard.cur'), auto !important ;
}

.mapboxgl-touch-drag-pan .mapboxgl-canvas-container.mapboxgl-interactive {
    cursor: url('../assets/cursor/lizard.cur'), auto !important ;
}

.mapboxgl-map.mode-draw_point .mapboxgl-canvas-container.mapboxgl-interactive,
.mapboxgl-map.mode-draw_line_string .mapboxgl-canvas-container.mapboxgl-interactive,
.mapboxgl-map.mode-draw_polygon .mapboxgl-canvas-container.mapboxgl-interactive {
    cursor: url('../assets/cursor/plus.cur'), auto !important ;
}

.capture-custom-layer-active_pointeur {
    cursor: url('../assets/cursor/pointeur_point.cur'), auto !important ;
}
