$hq-btn-min-with: 160px;

.btn-annuler,
.btn-non-conforme {
    width: 50%;
    background-color: rgb(230, 12, 12) !important;
    min-width: $hq-btn-min-with !important;
    border: none;
}

.btn-enregistrer,
.btn-conforme {
    width: 50%;
    background-color: green;
    min-width: $hq-btn-min-with !important;
}

.table-caption {
    display: flex;
    background-color: #1b75bb;
    padding: 1px 8px;
    text-align: left;
    color: black !important;
}

.caption-champ {
    width: 50%;
    font-weight: bolder;
}

.caption-detail {
    width: 35%;
    font-weight: bolder;
}

.btn-fermer {
    font-size: 22px;
}

.label-edit {
    padding-left: 8px !important;
}

.p-dropdown-panel .p-dropdown-item {
    white-space: pre !important;
}

.p-datatable-tbody .ng-invalid:not(form) {
    border: 2px solid $hq-color-DS-error-100;
}