$map-control-button-width: 46px;
$map-control-button-height: 46px;
$geocode-container-width: 420px;
$geocode-container-height: 40px;
$autocomplete-top: 10px;
$autocomplete-left: 80px;
$border-reduis: 0px;
$border-recherche-reduis: 4px;
$border-color: rgba(221, 221, 191, 1);
$recherche-button-width: 50px;
$recherche-button-height: 50px;
$creation-projet_toast-border-left: solid #1ea97c !important;
$creation-projet_toast-p-toast-message-content-padding: 8px !important;
$creation-projet_toast-p-toast-message-content-width: 420px !important;
$creation-projet_toast-toast-icon-color: #1ea97c !important;
$creation-projet_toast-toast-detail-padding-left: 12px !important;
$popUp-content_border-radius: 10px;
$popUp-content_border: 1px solid black;
$popUp-content_padding-right: 30px;
$popUp-content_width: 300px !important;
$popUp-close-button_font-size: 24px !important;
$popUp-masque-button_font-size: 14px;
$hq-popup_font-family: Montserrat;

.mapboxgl-ctrl-group button {
    width: $map-control-button-width !important;
    height: $map-control-button-height !important;
}

.geocode-container {
    position: absolute !important;
    top: $autocomplete-top !important;
    left: $autocomplete-left !important;
    margin: 0px;
    padding: 0px;
    width: $geocode-container-width;
    height: $geocode-container-height;

    .p-autocomplete {
        width: 380px;
        height: 100%;
        border-radius: $border-reduis;

        .p-inputtext {
            border: 1px solid #17649f !important;
            border-radius: $border-reduis;
            background-color: #ffff !important;
            color: $hq-color-primary-100;
            font-family: $hq-popup_font-family;
            font-size: 20px;
            line-height: $hq-line-height-regular;
            width: 100%;
            height: 100%;
            padding: 2px 32px 2px 10px;
        }

        .p-inputtext:hover,
        :active {
            border: 2px solid $hq-color-primary-100 !important;
        }
    }

    .p-button {
        border-radius: $border-reduis;
    }

    .p-autocomplete-item {
        cursor: pointer;
        overflow: hidden;
        position: relative;
        white-space: nowrap;
        border-top: solid 1px rgba(110, 110, 110, 0.3) !important;
    }
}

.offline-indicator {
    position: absolute !important;
    top: $autocomplete-top + $geocode-container-height + 10px !important;
    left: 10px !important;
}

.p-button-warning {
    min-width: 46px !important;
    height: 46px !important;
}

#geocode-input {
    font-size: 16px;
    margin: 0 2px 0 0;
    padding: 4px 8px;
}

#geocode-input {
    width: 300px;
}

.projet-button {
    >.p-button-label {
        flex: none !important;
    }
}

.p-toast {
    width: 21rem !important;
    opacity: 1 !important;
}

.p-toast-top-right {
    right: 5px !important;
}

.creation-projet_toast {
    display: flex;
    border-left: $creation-projet_toast-border-left;

    >.p-toast-message-content {
        padding: $creation-projet_toast-p-toast-message-content-padding;
        width: $creation-projet_toast-p-toast-message-content-width;
        justify-content: space-around;

        >.toast-icon {
            color: $creation-projet_toast-toast-icon-color;
            align-self: flex-start;
        }

        .toast-detail {
            display: flex;
            flex-direction: column;
            align-self: flex-start;
            padding-left: $creation-projet_toast-toast-detail-padding-left;
            font-family: $hq-font-regular;

            .toast-nom_projet {
                font-family: $hq-font-bold;
            }

            >.p-button {
                align-self: center;
            }

            ;
        }
    }
}

.p-slider {
    margin: 8px 0px;
}

.legende-popup .mapboxgl-popup-content {
    -webkit-box-shadow: 0 0 0.75em #777777;
    box-shadow: 0 0 0.75em #777777;
    border-radius: $popUp-content_border-radius;
    padding-right: $popUp-content_padding-right;
    width: $popUp-content_width;
    max-height: 460px;
    overflow-y: auto;
    font-family: $hq-popup_font-family;
}

.basic-popup-info .mapboxgl-popup-content {
    -webkit-box-shadow: 0 0 0.75em #777777;
    box-shadow: 0 0 0.75em #777777;
    border-radius: $popUp-content_border-radius;
    padding: 0;
    width: $popUp-content_width;
    max-height: 460px;
    overflow-y: auto;
    font-family: $hq-popup_font-family;
}

h4 {
    font-family: $hq-popup_font-family;
    padding: 3px 3px 3px 0;
}

h5 {
    font-family: $hq-popup_font-family;
    padding: 3px 3px 8px 0;
}

h6 {
    margin-left: 4px;
    padding-top: 2px;
    color: #036;
    font-size: 15px;
    line-height: 1.5;
    margin: 0;
    padding: 8px 8px 8px 0;
    font-family: $hq-popup_font-family;
    text-decoration: underline;
}

hr {
    border: none;
    border-top: 1px solid #ccc;
    margin-top: 6px;
    margin-bottom: 6px;
}

.legende-popup .mapboxgl-popup-content-wrapper {
    border: $popUp-content_border;
}

.legende-popup .mapboxgl-popup-close-button {
    font-size: $popUp-close-button_font-size;
}

.legende-popup .btn {
    border: none;
    background-color: inherit;
    font-size: $popUp-masque-button_font-size;
    font-weight: bold;
    cursor: pointer;
    margin: auto;
    display: block;
}

.btn:hover {
    background: #eee;
    text-decoration: underline;
}

.sync-button {
    color: gray !important;

    .badge {
        position: absolute;
        top: -10px;
        right: -10px;
        padding: 5px 10px;
        border-radius: 50%;
        background: red;
        color: white;
    }
}

.sync-available {
    color: green !important;
}

.anomalie-popup_button {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    background-color: transparent;

}

.anomalie-popup_button:hover {
    background-color: rgb(220, 222, 231);
}

.btn-colapse {
    color: #036;
}

.btn-colapse_hide {
    display: none;
}

.anomalie-titre {
    margin-left: 4px;
    padding-top: 2px;
}

.anomalie-titre_content {
    color: #036;
    font-size: 15px;
    line-height: 1.5;
    padding: 0px 0px 0px 5px;
    font-family: $hq-popup_font-family;
}

.anomalie-content_hide {
    display: none;
    overflow: hidden;
}

.anomalie-content_show {
    padding-left: 14px;
    display: block;
    background-color: transparent;
}

.equipement-mineur_titre {
    color: #036;
    font-size: 15px;
    line-height: 1.5;
    padding: 0px 0px 0px 5px;
    font-family: $hq-popup_font-family;
}

.equipement-mineur-content_hide {
    display: none;
    overflow: hidden;
}

.equipement-mineur-content_show {
    padding-left: 14px;
    display: block;
    background-color: transparent;
}