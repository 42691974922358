.p-multiselect {
    width: 100%;

    .p-multiselect-label {
        padding: 0.25rem; // Pour aligner le filtre avec les autres filtres
    }
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #FF9B00; // Orange officiel HQ
    color: #0F096C; // Bleu officiel HQ

    img {
        aspect-ratio: 1 / 1;
        width: 20px;
    }
}

.multi-selectcolumn-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap: 8px;
}