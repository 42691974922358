@mixin fullButton {
    height: 100%;
    width: 100%;
}

@mixin transparent {
    background-color: transparent;
    border: transparent;
}

@mixin flexRow {
    display: flex;
    flex-direction: row;
}

@mixin flexRowReverse {
    display: flex;
    flex-direction: row-reverse;
}

@mixin borderRadius0 {
    border-radius: 0;
}

@mixin focus {
    background: transparent;
    box-shadow: none;
    border-color: inherit;
}

@mixin buttonHover {
    &:enabled:hover {
        background-color: #00000038;
    }
}
