@import "./button";

.action-sheet-container {
    width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
    flex-direction: column;
    align-items: center;
}

/* Action sheet buttons */
@mixin actionSheetButton {
    @include fullButton;
    @include borderRadius0;
    @include transparent;
    @include buttonHover;

    &:enabled {
        &:hover {
            border-color: inherit;
        }

        &:focus {
            @include focus;
        }
    }
}

.actionSheetButtonBetween {
    @include actionSheetButton;
    @include flexRow;
    justify-content: space-between;

    @media screen and (max-width: 450px) {
        min-width: unset;
    }
}

.actionSheetButtonEnd {
    @include actionSheetButton;
    @include flexRow;
    justify-content: flex-end;

    @media screen and (max-width: 450px) {
        min-width: unset;
        @include flexRowReverse;
    }
}

.actionSheetRoundButtonCenter {
    @include actionSheetButton;
    justify-content: center;
    min-width: unset;
    border-radius: 50%;
    border: 4px solid white;

    @media screen and (max-width: 450px) {
        border-radius: unset;
        border: unset;
        justify-content: flex-start;
    }

    &:enabled {
        &:hover {
            border-color: inherit;
        }
    }
}
