@import "@ngxhq/theme-ngxhq/scss/_core";
@import "../assets/appicons/appicons";
@import "mapbox-gl/dist/mapbox-gl.css";
@import "primeflex/primeflex";
@import "primeicons/primeicons";

// Custom styles
@import "./variables";
@import "./confirm-dialog";
@import "./dialogs";
@import "./icons";
@import "./inputs";
@import "./map-buttons";
@import "./tables";
@import "./utils";
@import "./legende";
@import "./loading";
@import "./header";
@import "./options";
@import "./sidebar";
@import "./cursor";
@import "./imageviewer";
@import "./dropdowns";
@import "./multi-selects";
@import "components/components.scss";
@import "./action-sheet";
@import "./button";
@import "./hq-progress-bar";
@import "./photos";
@import "./shame";

.required,
.field-required,
.error {
    color: $error-color;
}