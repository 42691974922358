@for $i from 1 through 12 {
    .flex-#{$i} {
        flex: $i !important
    }
}

.justify-content-between {
    justify-content: space-between;
}

.word-break {
    word-break: break-all;
}
