.gps-icon {
    i {
        color: red;
    }

    &.online {
        i {
            color: green;
        }
    }

    &.offline {
        i {
            color: orange;
        }
    }
}
