$dialog-footer-padding: 8px;
$dialog-content-padding: 24px 40px;

.overflow-y-hidden .p-dialog-content {
    overflow-y: hidden;
}

.p-dialog .p-dialog-footer {
    border: 0;
    color: #036;
    @extend .p-d-flex !optional;
    justify-content: center;
    padding: $dialog-footer-padding;
}

.p-dialog .p-dialog-content {
    padding: $dialog-content-padding;
}

.p-dialog-contentinvisible {
    display: none;
}

.custom-dialog {
    .p-dialog-header-icon {
        display: none !important;
    }

    .custom-header {
        overflow: hidden;
        @extend .p-d-flex !optional;
        justify-content: space-between !important;
        align-items: center;
        padding: 1px 40px 0 !important;
    }

    .title {
        width: 500px;
    }

    .bt-groupe {
        margin-left: 2px;

        .custom-bt {
            background: transparent;
            border: 0;
            border-radius: 50%;
            color: #a6a6a6;
            height: 2rem;
            margin-right: .5rem;
            transition: background-color .2s, color .2s, box-shadow .2s;
            width: 2rem;
        }

        .custom-bt:enabled:hover {
            background: #dbdbdb;
            color: #036;
        }

        .fab-bt {
            width: 3rem !important;
            height: 3rem !important;
            border-radius: 50% !important;
        }

        .fab-bt:enabled:hover {
            background: #dbdbdb;
            color: #036;
        }
    }
}

.p-dialog .p-dialog-header {
    padding: 3px 0px 3px 40px;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    margin-right: 1rem !important;
}

.mobile-dialog {
    left: 0 !important;
    max-width: 100% !important;
    max-height: 100% !important;

    .p-dialog-header {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .p-dialog-content {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        height: 100%;
    }
}

.desktop-dialog {
    left: 0 !important;
    max-width: 100% !important;
    max-height: 100% !important;

    .p-dialog-header {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .p-dialog-content {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        height: 100%;
    }
}

.gps-dialog {
    .p-dialog-content {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        height: 100%;
        overflow: hidden !important;
    }
}

.select-projet-dialog {
    .p-dialog-content {
        overflow-y: visible !important;
    }
}

.detail-dialog {
    max-width: 100% !important;
    max-height: 100% !important;
    border-top: 0px !important;

    .p-dialog-content {
        padding: 0 !important;
        height: 100%;
    }

    .p-datatable .p-datatable-header {
        margin-bottom: 0px !important;
        position: sticky;
        top: 0px;
        z-index: 9999;
    }

    .footer-button {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 8px;
        padding: 8px;
    }
}

button[disabled] {
    background-color: white !important;
    color: #515151 !important;
    border: 1px solid #515151;
    opacity: 1 !important;
}

@media only screen and (min-width: 768px) {

    .detail-dialog,
    .mobile-dialog {
        left: 10px !important;
        max-width: 400px !important;
        max-height: 100% !important;
        overflow: visible;
        position: absolute !important;
    }
}

@media (max-width: 768px) {
    .p-dialog {
        left: 0 !important;
    }
}

.p-confirm-dialog-message {
    white-space: pre-line;
}

.custom-dialog-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding-top: 24px;

    &>button {
        min-width: auto;
        width: auto;
    }
}