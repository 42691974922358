.app-form-field {
    font-family: "Open Sans Regular", Arial, Helvetica, sans-serif;

    .p-field {
        display: flex;
        flex-direction: row;
        align-items: center;

        label {
            font-size: 0.875rem;
        }

        .infos {
            font-size: 0.875rem;
        }

        input {
            flex: 1;
            margin-left: 10px;
        }

        p {
            margin-left: 10px;
        }
    }

    &--column {
        .p-field {
            margin-bottom: 25px;
            flex-direction: column;
            align-items: flex-start;

            label {
                margin-bottom: 5px;
            }

            input {
                margin-left: 0;
            }
        }
    }

    &--error-absolute {
        .p-field {
            position: relative;

            app-form-field-error {
                position: absolute;
                left: 12px;
                top: -18px;
                font-size: small;
            }
        }
    }
}
