$icon-n-size: 1rem;
$icon-lg-size: 1.25rem;
$icon-xl-size: 1.5rem;
$icon-xxl-size: 1.75rem;

@mixin create-icon-size($key, $size) {
    .icon-#{$key} {
        font-size: $size !important;
    }
}

@include create-icon-size(n, $icon-n-size);
@include create-icon-size(lg, $icon-lg-size);
@include create-icon-size(xl, $icon-xl-size);
@include create-icon-size(xxl, $icon-xxl-size);

.icon-red {
    color: red;
}
