$legende-header-padding:0px 40px;
$legende-content-padding:8px;
$legende-tree-border:0px;
$legende-tree-color:#036;
$legende-tree-padding:17px;
$legende-tree-treenode-content-border:0px;
$legende-tree-treenode-content-padding:1px;
$legende-tree-treenode-content-treenode-label-padding:0px;
$legende-content-checkbox-label-margin-left:0px;
.legendeContent {
    background:white !important;
}
.legendeContent .p-dialog-header{
    padding:$legende-header-padding;
}
.legendeContent .p-dialog-content{
    padding: $legende-content-padding;
}
.p-tree {
    background-color:white;
    border: $legende-tree-border;
    color:$legende-tree-color;
    padding: $legende-tree-padding;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
    background-color: white;
    border: $legende-tree-treenode-content-border;
    color: $legende-tree-color;
    padding: $legende-tree-treenode-content-padding;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content:focus{
    box-shadow:none;
    outline: none;
}
.legendeContent .p-checkbox-label{
    margin-left: $legende-content-checkbox-label-margin-left;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label{
padding: $legende-tree-treenode-content-treenode-label-padding;
}

