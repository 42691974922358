.photos {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__title {
        font-weight: bold;
        margin-bottom: 5px;
    }

    &__list {
        margin-bottom: 10px;
    }

    &__upload {
        width: 100%;
    }
}
