$button-border: none;
$button-color: white;
$button-color_hover: rgb(120, 59, 218) !important;
$button-background_color: transparent;
$button-transition: 0.6s ease;
$capture-viewer_background: black;

.photoContainer {
    display: flex;
    flex-flow: row wrap;
    max-width: 300px;
    align-items: flex-start;
}

.thumbnailImage {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 1px;
    width: 50%;
    height: 50%;
}

.capture-image_viewer {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: $capture-viewer_background;
    z-index: 1200;
    border-top: 10px solid #1b75bb;
}

.capture-image_viewer-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    width: fit-content;
    height: calc(100% - 57px);
    max-width: 1200px;
}

.inspectionPhotos {
    display: none;
    width: 100%;
    height: 100%;
}

.precedent,
.suivant {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    color: $button-color;
    font-weight: bold;
    font-size: 20px;
    transition: $button-transition;
    border-radius: 0 3px 3px 0;
    border: $button-border;
    user-select: none;
    background-color: $button-background_color;
}

.suivant {
    right: 0;
    border-radius: 3px 0 0 3px;
}

.caption-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    background-color: $capture-viewer_background;
    padding: 2px 16px;
    color: white;
}

.nomPhoto {
    font-family: $hq-font-bold;
}
.caption-button {
    display: flex;
}
.caption-close-btn {
    background-color: $button-background_color;
    color: $button-color;
    border: $button-border;
    transition: $button-transition;
    font-weight: bold;
}

.caption-telecharger-btn {
    background-color: $button-background_color;
    color: $button-color;
    border: $button-border;
}

.caption-close-btn:hover,
.caption-telecharger-btn:hover,
.precedent:hover,
.suivant:hover {
    background-color: rgba(0, 0, 0, 0.8);
    color: $button-color_hover;
    text-decoration: none;
    cursor: pointer;
}
