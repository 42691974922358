$hq-header-submenu__section-padding: 0px !important;
$p-menu-width: 240px !important;
$p-menuitem-text-color:black !important;
$p-menuitem-icon-color:black !important;
$hq-header-submenu__section-max-width: 340px !important;

.hq-header-menu-item-group-level-one .hq-header-submenu {
    background-color: white !important;
    max-width: $hq-header-submenu__section-max-width;
}

.hq-header-submenu__section:first-child {
    background-color: transparent !important;
    padding: $hq-header-submenu__section-padding;
}

.hq-header-submenu__section .p-menu {
    width: $p-menu-width;
}

.hq-header-submenu__section .p-menuitem-text {
    color: $p-menuitem-text-color;
    font-family: $hq-font-semi-bold;
}

.hq-header-submenu__section .p-menuitem-icon {
    color: $p-menuitem-icon-color;
}

.hq-header-section__title .hq-header-menu__icon {
    display: none !important;
}

.hq-header__logo {
    pointer-events: none !important;
}
