$confirm-dialog-footer-padding: 8px;

.custom-confirm-footer>.p-dialog-mask>.p-dialog>.p-dialog-footer {
    border: 0;
    color: #036;
    @extend .p-d-flex !optional;
    justify-content: center;
    padding: $confirm-dialog-footer-padding;

    &>.p-confirm-dialog-accept,
    &>.p-confirm-dialog-reject {
        background-color: transparent;
        border-color: transparent;
        color: #1b75bb;
    }
}