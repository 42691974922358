$assign-button-width: 26px;
$assign-button-height: 26px;
$datatable-header-margin: 8px;
$datatable-header-padding: 0px;
$datatable-sortable-column-margin: 2px;
$selectbutton-highlight-color_vert: rgba(118, 185, 29, 0.7);
$selectbutton-highlight-color_gris: rgb(114, 112, 112);
$selectbutton-border: 1px solid rgb(200, 200, 200);
$search-input-margin: 16px;

.p-datatable .p-datatable-scrollable-header {
    background-color: #fff;
}

.p-datatable .p-datatable-header {
    padding: $datatable-header-padding;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
    margin-left: $datatable-sortable-column-margin
}

// .p-datatable .p-datatable-scrollable-body td:last-child {
//     background: #fff;
//     position: sticky;
//     right: 0;
// }

.assigne-button {
    width: $assign-button-width !important;
    height: $assign-button-height !important;
}

.sticky-column {
    right: 0px;
    position: sticky;
    width: 5%;

    &__container {
        display: flex;
        justify-content: center;
    }
}

.sticky-column-transparente {
    right: 0px;
    position: sticky;
    width: 5%;
    background-color: #ffffffba !important;

    &__container {
        display: flex;
        justify-content: center;
    }
}

.search {
    text-align: left;
    margin-bottom: $search-input-margin;
    margin-top: $search-input-margin;

    &__icon {
        margin: 4px 4px 0 0;
    }

    &__input {
        width: auto;
    }
}

.table-mobile {
    height: 25% !important;
    overflow-y: auto;
}

.full-table-mobile {
    height: 100% !important;
    overflow-y: auto;
}

.modification-dialog {
    display: flex;
    flex-direction: column;
}

.modification {
    display: inline-flex !important;
    align-items: center;
}

.modification-field {
    width: 30%;
    margin-left: 6px !important;
}

.vertical-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid gray;
    margin-bottom: 5px;

    tr:nth-child(4n-3),
    tr:nth-child(4n-1) {
        background: #f9f9f9;
    }

    th,
    td {
        padding: 0.75rem;
        text-align: left;
    }

    th {
        border-right: 1px solid gray;
    }

    tr {
        display: flex;

        th {
            flex: 1;
        }

        td {
            flex: 2;
        }
    }
}

.detail-table {
    width: 100%;

    tr {
        display: flex;
    }

    td {
        flex: 2;
        padding: 0.571rem 0.457rem !important;
        color: black;
    }

    .label-edit {
        padding-left: 8px !important;
        font-weight: bold;
    }

    .table-caption {
        display: flex;
        background-color: #1b75bb;
        padding: 15px 0px 15px 17px;
        text-align: left;
        color: white !important;
        flex-flow: row wrap;
        align-items: center;
        font-size: 16px !important;
        font-family: Arial, Helvetica, sans-serif !important;
    }

    .text-erreur {
        color: #e60c0c;
    }
}

.equipement-info {
    padding-top: 1rem;
    height: 90vh;
}

.footer {
    display: flex;
    position: absolute;
    width: 100%;
    bottom: 1rem;
    padding-right: 1rem;
}

.equipement-table {
    width: 100%;

    tr {
        display: flex;
        align-items: center;
        text-align: left;
        color: black;
        padding: 0.571rem 0.457rem !important;

        th {
            flex: 3;
        }

        td {
            flex: 2;
        }
    }
}

.p-selectbutton .p-button {
    min-width: 40px !important;
    color: black;
    border: $selectbutton-border;
    border-radius: 0px !important;
}

.p-selectbutton .p-button.p-highlight {
    background-color: $selectbutton-highlight-color_vert !important;
    border: $selectbutton-border;
    color: white;
    font-weight: bolder;
}

.p-buttonset .p-button:last-of-type.p-highlight {
    background-color: $selectbutton-highlight-color_gris !important;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    width: 2.125em;
}

.anomalie-table {
    height: calc(45vh - 195px);

    >.p-datatable-wrapper {
        height: 100%;
        overflow-y: scroll;
    }
}

.input-filter,
.multiSelect-filter>div,
.dropdown-filter>div {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1803921569);
    height: 24px;
    font-size: 14px;
    border-radius: 5px;
    padding-left: 4px;
    min-width: 100%;

    &:focus,
    &:hover {
        border-color: #1b75bb;
        color: #1b75bb;
        outline: none;
    }

    &:focus-visible {
        border: 1px solid rgba(0, 0, 0, 0.1803921569);
        color: #1b75bb;
        box-shadow: 0 0 0 .2rem #b3d1e8;
        outline: 0 none;
        outline-offset: 0;
    }
}

.input-filter:focus::placeholder,
.input-filter:focus-visible::placeholder,
.input-filter:hover::placeholder {
    color: #1b75bb;
    opacity: 1;
}

.column-resizer>span.p-column-resizer {
    width: 1rem;
}